document.getElementById("menu-btn").addEventListener("click", toggleMenu);
function toggleMenu() {
  const btn = document.getElementById("menu-btn").checked;
  const nav = document.getElementById("nav");
  const body = document.body;
  console.log(btn);
  if (btn) {
    nav.classList.add("mobile-dark");
    body.classList.add("nav-opened");
  } else {
    nav.classList.remove("mobile-dark");
    body.classList.remove("nav-opened");
  }
}

function setTime() {
  var n = new Date();
  document.getElementById('time').innerText =
    n.toLocaleString("en-US", {
      timeStyle: "short"
    });
}

setTime();
setInterval(() => setTime(), 1000);

//===========

function scrollToSection(sectionId) {
  const section = document.getElementById(sectionId);
  window.scrollTo({
    top: section.offsetTop - 140,
    behavior: 'smooth'
  });
}

if (document.getElementById('dedicatedTeamHeaderButton')) {
  const dedicatedTeamHeaderButton = document.getElementById('dedicatedTeamHeaderButton');
  dedicatedTeamHeaderButton.onclick = () => scrollToSection('your-team');
}

if (document.getElementById('joinUsButton')) {
  const joinUsButton = document.getElementById('joinUsButton');
  joinUsButton.onclick = () => scrollToSection('looking-for');
}

if (document.getElementById('seePositionsButton')) {
  const joinUsButton = document.getElementById('seePositionsButton');
  joinUsButton.onclick = () => scrollToSection('looking-for');
}

if (document.getElementById('servicesHeaderArrow')) {
  const servicesHeaderArrow = document.getElementById('servicesHeaderArrow');
  servicesHeaderArrow.onclick = () => scrollToSection('servicesScrolledSection');
}

if (document.getElementById('homepageHeaderCta')) {
  const servicesHeaderArrow = document.getElementById('homepageHeaderCta');
  servicesHeaderArrow.onclick = () => scrollToSection('customers');
}

window.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname === '/' && window.sessionStorage.getItem('element') !== '') {
    scrollMenu(window.sessionStorage.getItem('element'));
    window.sessionStorage.setItem('element', '');
  }
});
